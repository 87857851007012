import { SUBSCRIPTION_FEATURES } from "Components/constants/subscription";
import { Permission, hasPermissions } from "common/security";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectProfile } from "selectors";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState<boolean>(false);
    const [isOrders, setIsOrders] = useState<boolean>(false);
    const [isCampaigns, setIsCampaigns] = useState<boolean>(false);
    const [isReviews, setIsReviews] = useState<boolean>(false);
    const [isChat, setIsChat] = useState<boolean>(false);
    const [isApps, setIsApps] = useState<boolean>(false);
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [isPages, setIsPages] = useState<boolean>(false);
    const [isTemplates, setIsTemplates] = useState<boolean>(false);
    const [isBaseUi, setIsBaseUi] = useState<boolean>(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState<boolean>(false);
    const [isForms, setIsForms] = useState<boolean>(false);
    const [isTables, setIsTables] = useState<boolean>(false);
    const [isCharts, setIsCharts] = useState<boolean>(false);
    const [isIcons, setIsIcons] = useState<boolean>(false);
    const [isMaps, setIsMaps] = useState<boolean>(false);
    const [isMultiLevel, setIsMultiLevel] = useState<boolean>(false);

    // Apps
    const [isEmail, setEmail] = useState<boolean>(false);
    const [isSubEmail, setSubEmail] = useState<boolean>(false);
    const [isEcommerce, setIsEcommerce] = useState<boolean>(false);
    const [isProjects, setIsProjects] = useState<boolean>(false);
    const [isTasks, setIsTasks] = useState<boolean>(false);
    const [isCRM, setIsCRM] = useState<boolean>(false);
    const [isCrypto, setIsCrypto] = useState<boolean>(false);
    const [isInvoices, setIsInvoices] = useState<boolean>(false);
    const [isSupportTickets, setIsSupportTickets] = useState<boolean>(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState<boolean>(false);
    const [isJobs, setIsJobs] = useState<boolean>(false);
    const [isJobList, setIsJobList] = useState<boolean>(false);
    const [isCandidateList, setIsCandidateList] = useState<boolean>(false);


    // Authentication
    const [isSignIn, setIsSignIn] = useState<boolean>(false);
    const [isSignUp, setIsSignUp] = useState<boolean>(false);
    const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState<boolean>(false);
    const [isLockScreen, setIsLockScreen] = useState<boolean>(false);
    const [isLogout, setIsLogout] = useState<boolean>(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState<boolean>(false);
    const [isVerification, setIsVerification] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    // Pages
    const [isProfile, setIsProfile] = useState<boolean>(false);
    const [isLanding, setIsLanding] = useState<boolean>(false);


    // Charts
    const [isApex, setIsApex] = useState<boolean>(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);
    const [isLevel2, setIsLevel2] = useState<boolean>(false);

    const profile = useSelector(selectProfile);
    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    const isOwner = profile.currentStoreRole == 'OWNER'
    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Campaigns') {
            setIsCampaigns(false);
        }
        if (iscurrentState !== 'Templates') {
            setIsTemplates(false);
        }
        if (iscurrentState !== 'Reviews') {
            setIsReviews(false);
        }
        if (iscurrentState !== 'Chat') {
            setIsChat(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Orders') {
            setIsOrders(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
        if (iscurrentState !== 'CRM') {
            setIsCRM(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);

    const menuItems: any = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Analíticas de campaña",
            icon: "mdi mdi-chart-line",
            link: "/dashboard",
            permissions: [Permission.METRICS],
            stateVariables: isDashboard,
            click: function (e: any) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },
        {
            id: "templates",
            label: "Plantillas de mensaje",
            icon: "mdi mdi-message-text-outline",
            link: "/templates/",
            permissions: [Permission.TEMPLATE_CREATE, Permission.TEMPLATE_DELETE, Permission.TEMPLATE_VIEW],
            stateVariables: isTemplates,
            click: function (e: any) {
                e.preventDefault();
                setIsTemplates(!isTemplates);
                setIscurrentState('Templates');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "templates-list",
                    label: "Mis mensajes",
                    icon: "mdi mdi-format-list-bulleted",
                    link: "/templates",
                    parentId: "templates",
                },
                {
                    id: "templates-create",
                    label: "Crear mensaje",
                    icon: "mdi mdi-new-box",
                    link: "/templates/new",
                    parentId: "templates",
                    permissions: [Permission.TEMPLATE_CREATE]
                },
            ],
        },
        {
            id: "reviews",
            label: "Reseñas",
            icon: "mdi mdi-star",
            link: "/reviews",
            permissions: [Permission.REVIEW_APPROVE, Permission.REVIEW_CONFIG, Permission.REVIEW_VIEW],
            stateVariables: isReviews,
            click: function (e: any) {
                e.preventDefault();
                setIsReviews(!isReviews);
                setIscurrentState('Reviews');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "reviews-dashboardd",
                    label: "Dashboard",
                    icon: "mdi mdi-monitor-dashboard",
                    link: "/reviews/dashboard",
                    permissions: [Permission.REVIEW_APPROVE, Permission.REVIEW_VIEW],
                    parentId: "reviews",
                }, {
                    id: "reviews-list",
                    label: "Ver Reseñas",
                    icon: "mdi mdi-format-list-bulleted",
                    link: "/reviews/list",
                    permissions: [Permission.REVIEW_APPROVE, Permission.REVIEW_VIEW],
                    parentId: "reviews",
                }, {
                    id: "reviews-list",
                    label: "Configuración",
                    icon: "mdi mdi-message-settings",
                    link: "/reviews/config",
                    permissions: [Permission.REVIEW_CONFIG],
                    parentId: "reviews",
                },
            ]
        },
        {
            id: "campaigns",
            label: "Campañas",
            icon: "mdi mdi-bullhorn-variant",
            link: "/campaigns",
            permissions: [Permission.CAMPAIGN_CREATE, Permission.CAMPAIGN_DELETE, Permission.CAMPAIGN_EDIT, Permission.CAMPAIGN_VIEW],
            stateVariables: isCampaigns,
            click: function (e: any) {
                e.preventDefault();
                setIsCampaigns(!isCampaigns);
                setIscurrentState('Campaigns');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "campaign-list",
                    label: "Mis campañas",
                    icon: "mdi mdi-format-list-bulleted",
                    link: "/campaigns/",
                    parentId: "campaigns",
                    permissions: [Permission.CAMPAIGN_CREATE, Permission.CAMPAIGN_DELETE, Permission.CAMPAIGN_EDIT, Permission.CAMPAIGN_VIEW],
                },
                {
                    id: "campaign-create",
                    label: "Crear campaña",
                    icon: "mdi mdi-new-box",
                    link: "/campaigns/new",
                    parentId: "campaigns",
                    permissions: [Permission.CAMPAIGN_CREATE],
                },
            ]
        },
        {
            id: "chat",
            label: "Chat",
            icon: "mdi mdi-send-outline",
            link: "/chat",
            stateVariables: isChat,
            permissions: [Permission.CRM_CHAT, Permission.CRM_ASSISTANT],
            click: function (e: any) {
                e.preventDefault();
                setIsChat(!isChat);
                setIscurrentState('Chat');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "conversations",
                    label: "Conversaciones",
                    icon: "mdi mdi-chat",
                    link: "/chat/conversations",
                    parentId: "chat",
                    permissions: [Permission.CRM_CHAT],
                },
                {
                    id: "assistant",
                    label: "Burbujita AI",
                    icon: "mdi mdi-mace",
                    link: "/chat/assistant",
                    parentId: "chat",
                    permissions: [Permission.CRM_ASSISTANT],
                },
            ]
        },
        {
            id: "orders",
            label: "Todos los pedidos",
            icon: "mdi mdi-cart-heart",
            link: "/orders/",
            stateVariables: isOrders,
            permissions: [Permission.STORE_ORDERS],
            click: function (e: any) {
                e.preventDefault();
                setIsOrders(!isOrders);
                setIscurrentState('Orders');
                updateIconSidebar(e);
            },
        },
        {
            id: "crm",
            label: "CRM",
            icon: "mdi mdi-account-group",
            stateVariables: isCRM,
            permissions: [Permission.CRM_TICKET],
            click: function (e: any) {
                e.preventDefault();
                setIsCRM(!isCRM);
                setIscurrentState('CRM');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "tickets",
                    label: "Tickets",
                    icon: "mdi mdi-ticket",
                    link: "/crm/tickets",
                    parentId: "crm",
                },
                {
                    id: "contacts",
                    label: "Contactos",
                    icon: "mdi mdi-account-group",
                    link: "/crm/contacts",
                    parentId: "crm",
                },
                {
                    id: "archived",
                    label: "Tickets archivados",
                    icon: "mdi mdi-archive",
                    link: "/crm/tickets/archived",
                    parentId: "crm",
                },
            ]
        }
    ];

    const filteredItems = (menuItems
        .filter(
            (each: any) => !each.permissions || hasPermissions(each.permissions, profile)
        )
        .map(({ subItems, ...anotherProps }: any) => ({
            ...anotherProps,
            subItems: subItems?.filter((subItem: any) => !subItem.permissions || hasPermissions(subItem.permissions, profile))
        }))
    )
    return <React.Fragment>{filteredItems}</React.Fragment>;
};
export default Navdata;
