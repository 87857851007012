import React, { useRef, useState } from 'react';
import { Col, Row, Button, Container, Card, CardBody, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es"

import CampaignDropDown from './CampaignDropDown';
import { ICampaign } from 'types/Dashboard';
import GroupDropDown from './GroupDropDown';
import CurrencyStoreDropdown from './CurrencyStoreDropdown';

interface CampaignFilterProps {
    onChange: (param: any) => void
    values: any,
}

const CampaignFilter = (props: CampaignFilterProps) => {
    const dateRef = useRef<any>(null)
    const [openFilters, setOpenFilters] = useState(false)

    const _clearDate = () => {
        if (dateRef.current) {
            dateRef.current.flatpickr.clear();
        }
    }

    const content = <>
        <div className="flex-grow-1">
        </div>
        <div className="mt-3 mt-lg-0">
            <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                    <div className="col-sm-auto">
                        <div className="input-group">
                            <Button color='danger' onClick={() => _clearDate()}>
                                <i className="mdi mdi-close"></i>
                            </Button>
                            <Flatpickr
                                ref={dateRef}
                                value={[props.values.fromDate ? new Date(props.values.fromDate) : null, props.values.toDate ? new Date(props.values.toDate) : null]}
                                className="form-control border-0 dash-filter-picker shadow"
                                options={{
                                    locale: Spanish,
                                    mode: "range",
                                    dateFormat: "d M, Y",
                                    defaultDate: [],
                                }}
                                onChange={(selectedDates: any, dateStr: any, instance: any) => {
                                    const params = {
                                        fromDate: selectedDates.length > 0 ? selectedDates[0].toISOString() : null,
                                        toDate: selectedDates.length > 1 ? selectedDates[1].toISOString() : null,
                                    }

                                    props.onChange(params)
                                }}
                            />
                            <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                        </div>
                    </div>
                    {!props.values.campaign && <div className="col-auto">
                        <GroupDropDown
                            value={props.values.group}
                            placeholder="Seleccionar conjunto de campañas"
                            onSelect={(group) => props.onChange({
                                group: group ? group.id : null
                            })}
                        />
                    </div>}
                    <div className="col-auto">
                        <CurrencyStoreDropdown
                            value={props.values.currency}
                            placeholder="Seleccionar moneda del reporte"
                            onSelect={(currency: 'USD' | 'STORE') => props.onChange({
                                currency
                            })}
                        />
                    </div>
                    {!props.values.group && <div className="col-auto">
                        <CampaignDropDown
                            placeholder="Seleccionar campaña"
                            value={props.values.campaign}
                            onSelect={(campaign) => props.onChange({
                                campaign: campaign ? campaign.id : null
                            })}
                        />
                    </div>}
                </Row>
            </form>
        </div>
    </>

    return (
        <React.Fragment>
            <Row className="mb-3 pb-1 d-block d-lg-none">
                <Row>
                    <Col className='text-end m-0 mb-2 p-0'>
                        <Button color='info' onClick={() => setOpenFilters(true)}>
                            <i className='ri-filter-line'></i>
                        </Button>
                    </Col>
                </Row>
            </Row>
            <Offcanvas isOpen={openFilters} toggle={() => setOpenFilters(false)} direction="end">
                <OffcanvasHeader toggle={() => setOpenFilters(false)} id="offcanvasRightLabel">
                    Filtros del reporte
                </OffcanvasHeader>
                <OffcanvasBody>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-row">
                        {content}
                    </div>
                </OffcanvasBody>
            </Offcanvas>
            <Row className="mb-3 pb-1 d-none d-lg-block">
                <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        {content}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CampaignFilter;
