import { Permission } from "common/security"
import { Badge, Table } from "reactstrap"
import UserActionDropDown, { UserAction } from "./UserActionDropDown"
import { BasicUser } from "types/Dashboard"


interface UserTableProps {
    users: {
        uid: string,
        first_name: string,
        last_name: string,
        email: string,
        role: 'OWNER' | 'MEMBER',
        permissions: Permission[],
    }[]
    onAction: (action: UserAction, user: BasicUser) => void,
}

const UserTable = (props: UserTableProps) => {
    return <>
        <Table size="sm" striped hover>
            <thead>
                <tr>
                    <th>E-mail</th>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Rol</th>
                    <th>Permisos</th>
                </tr>
            </thead>
            <tbody>
                {props.users.map((each) => <tr className="fs-10">
                    <td className="align-middle">
                        <UserActionDropDown
                            onClick={(action: any, userId: any) => props.onAction(action, each)}
                            userId={each.uid}
                        />
                    </td>
                    <td className="align-middle">{each.email}</td>
                    <td className="align-middle">{each.first_name}</td>
                    <td className="align-middle">{each.last_name}</td>
                    <td className="align-middle">{each.role}</td>
                    <td className="align-middle">
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>{(each.permissions || []).map((perm) => <Badge size="small" color="primary" className="m-1 fs-8">{perm}</Badge>)}
                        </div>
                    </td>
                </tr>)}
            </tbody>
        </Table>
    </>
}

export default UserTable