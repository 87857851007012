import BreadCrumb from 'Components/Common/BreadCrumb';
import Pagination from 'Components/Common/Pagination';
import useCurrentStore from 'Components/Hooks/CurrentStore';
import MessageTable from 'Components/Messages/MessageTable';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { selectMessage, selectProfile } from 'selectors';
import { getMessageList } from 'slices/message/thunk';


const MessageList = () => {
    useCurrentStore()
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const messages = useSelector(selectMessage)

    const fetchPage = useCallback((page: any) => {
        if (currentStoreId) {
            dispatch(
                getMessageList({
                    storeId: currentStoreId,
                    skip: page
                })
            )
        }
    }, [currentStoreId])

    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
        }
    }, [currentStoreId]);

    document.title = 'Mensajes enviados | Burbuxa'

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Listar órdenes" pageTitle="Mensajes" />

                <Container fluid>
                    <Row>
                        <Col xxl={12} className="text-end mb-2 d-flex justify-content-end gap-2">
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <CardBody className="p-0">
                                    <MessageTable loading={messages.status == 'loading'} messages={messages.messageItems} showTitle={false} />
                                    <Pagination
                                        total={messages.limit !== 0 ? Math.ceil(messages.total / messages.limit) : 0}
                                        data={messages.messageItems}
                                        setCurrentPage={(page: any) => fetchPage((page - 1) * messages.limit)}
                                        currentPage={messages.limit !== 0 ? Math.ceil(messages.skip / messages.limit) + 1 : 0}
                                        perPageData={messages.limit}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    </React.Fragment>
}

export default MessageList 
