import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap'
import { selectProfile, selectStore } from 'selectors'
import { getProducts } from 'slices/stores/thunk'



interface ReviewFilterProps {
    setFilter: (filters: any) => void
    filter: any
    onExport: (filters: any) => void
    onImport: () => void
}

const ReviewFilter = ({ filter, setFilter, onExport, onImport }: ReviewFilterProps) => {
    const dispatch: any = useDispatch()
    const profile = useSelector(selectProfile)
    const { products } = useSelector(selectStore)
    const [openFilters, setOpenFilters] = useState(false)

    useEffect(() => {
        if (profile && profile.currentStoreId) {
            dispatch(
                getProducts({
                    storeId: profile.currentStoreId
                })
            )
        }
    }, [dispatch, profile.currentStoreId])

    const onChangeValue = useCallback((name: string, value: any) => {
        const payload = {
            ...filter,
            [name]: value ? value : null
        }

        setFilter(payload)
    }, [filter.date, filter.product_id, filter.rate])

    const content = <>

    </>

    return <>
        <Row className="mb-3 pb-1 d-block d-lg-none">
            <Row>
                <Col className='text-end m-0 mb-2 p-0'>
                    <Button color='info' onClick={() => setOpenFilters(true)}>
                        <i className='ri-filter-line'></i>
                    </Button>
                </Col>
            </Row>
        </Row>
        <div className="border-0 rounded d-none d-lg-block mb-2" >
            <div className='card'>
                <div className='card-header'>
                    <div className="g-2 row">
                        <div className="col-xl-2">
                            <div className="search-box">
                                <Input type="select" name="product_id" onChange={(e) => {
                                    onChangeValue(e.target.name, e.target.value)
                                }}>
                                    <option value="">-- Seleccione --</option>
                                    {(products || []).map((each: any) =>
                                        <option value={each.id}>{each.title}</option>)}
                                </Input>
                            </div>
                        </div>
                        <div className='col-xl-2'>
                            <div className='search-box'>
                                <Input type="date" name="date" placeholder='Fecha' onChange={(e) => onChangeValue(e.target.name, e.target.value)} />
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="search-box">
                                <Input type="select" name="rate" onChange={(e) => {
                                    onChangeValue(e.target.name, e.target.value)
                                }}>
                                    <option value="">-- Seleccione rating --</option>
                                    <option value="1">⭐ (1 estrella)</option>
                                    <option value="2">⭐⭐ (2 estrellas)</option>
                                    <option value="3">⭐⭐⭐ (3 estrellas)</option>
                                    <option value="4">⭐⭐⭐⭐ (4 estrellas)</option>
                                    <option value="5">⭐⭐⭐⭐⭐ (5 estrellas)</option>
                                </Input>
                            </div>
                        </div>
                        <div className='col-xl-6 text-end'>
                            <Button color='primary' onClick={(e) => onExport(filter)}>
                                <i className='ri-download-2-line'></i>
                                {' '}
                                Exportar a CSV
                            </Button>{' '}
                            <Button color='primary' onClick={(e) => onImport()}>
                                <i className='ri-upload-2-line'></i>
                                {' '}
                                Importar desde CSV
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Offcanvas isOpen={openFilters} toggle={() => setOpenFilters(false)} direction="end">
            <OffcanvasHeader toggle={() => setOpenFilters(false)} id="offcanvasRightLabel">
                Filtros de reseñas
            </OffcanvasHeader>
            <OffcanvasBody>
                <div className="d-flex align-items-lg-center flex-lg-row flex-column" style={{
                    gap: '10px'
                }}>
                    <div className="col-xs-12">
                        <div className="search-box">
                            <Input type="select" name="product_id" onChange={(e) => {
                                onChangeValue(e.target.name, e.target.value)
                            }}>
                                <option value="">-- Seleccione --</option>
                                {(products || []).map((each: any) =>
                                    <option value={each.id}>{each.title}</option>)}
                            </Input>
                        </div>
                    </div>
                    <div className='col-xs-12'>
                        <div className='search-box'>
                            <Input type="date" name="date" placeholder='Fecha' onChange={(e) => onChangeValue(e.target.name, e.target.value)} />
                        </div>
                    </div>
                    <div className="col-xs-12">
                        <div className="search-box">
                            <Input type="select" name="rate" onChange={(e) => {
                                onChangeValue(e.target.name, e.target.value)
                            }}>
                                <option value="">-- Seleccione rating --</option>
                                <option value="1">⭐ (1 estrella)</option>
                                <option value="2">⭐⭐ (2 estrellas)</option>
                                <option value="3">⭐⭐⭐ (3 estrellas)</option>
                                <option value="4">⭐⭐⭐⭐ (4 estrellas)</option>
                                <option value="5">⭐⭐⭐⭐⭐ (5 estrellas)</option>
                            </Input>
                        </div>
                    </div>
                    <div className='col-xs-12'>
                        <Button color='primary' style={{ width: '100%' }} onClick={(e) => onExport(filter)}>
                            <i className='ri-download-2-line'></i>
                            {' '}
                            Exportar a CSV
                        </Button>
                    </div>
                    <div className='col-xl-12'>
                        <Button color='primary' style={{ width: '100%' }} onClick={(e) => onImport()}>
                            <i className='ri-upload-2-line'></i>
                            {' '}
                            Importar desde CSV
                        </Button>
                    </div>
                </div>
            </OffcanvasBody>
        </Offcanvas>

    </>
}

export default ReviewFilter