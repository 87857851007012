import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Button, Row, Col } from 'reactstrap'
import GroupModal from './GroupModal'
import { selectCurrentGroup, showGroupModal } from 'slices/groups/reducer'
import { selectGroup, selectProfile } from 'selectors'
import { createGroup, deleteGroup, getGroups } from 'slices/groups/thunk'
import classNames from 'classnames'
import { Permission, hasPermissions } from 'common/security'


const GroupMenu = () => {
    const dispatch: any = useDispatch()
    const groups = useSelector(selectGroup)
    const profile = useSelector(selectProfile)

    const showModal = (show: boolean) => {
        dispatch(
            showGroupModal(show)
        )
    }

    useEffect(() => {
        dispatch(selectCurrentGroup(null));
    }, []);

    useEffect(() => {
        if (profile.currentStoreId) {
            dispatch(
                getGroups({
                    storeId: profile.currentStoreId
                })
            )
        }
    }, [dispatch, profile.currentStoreId])


    useEffect(() => {
        if (['created', 'deleted'].indexOf(groups.status) !== -1 && profile.currentStoreId) {
            dispatch(
                getGroups({
                    storeId: profile.currentStoreId
                })
            )
        }
    }, [groups.status, dispatch, profile.currentStoreId])

    return <React.Fragment>
        <Card>
            <CardBody>
                {hasPermissions([Permission.CAMPAIGN_CREATE, Permission.CAMPAIGN_EDIT], profile) && <Row>
                    <Col xxl={12}>
                        <Button
                            color='primary'
                            size='large'
                            className='w-100'
                            onClick={() => showModal(true)}
                        >
                            <i className='mdi mdi-plus'></i>
                            {' '}Crear conjunto de campañas
                        </Button>
                    </Col>
                </Row>}
                <Row>
                    <Col xxl={12}>
                        <p className="text-muted mt-2 mb-1">
                            Filtrar campañas por conjunto
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={12}>
                        <button
                            color='light'
                            className={
                                classNames('w-100 mb-1 btn text-dark text-start', {
                                    'bg-light-subtle': groups.currentGroup == null,
                                    'text-light': groups.currentGroup == null,
                                })
                            }
                            onClick={() => dispatch(selectCurrentGroup(null))}>
                            Todas las campañas
                        </button>
                    </Col>
                </Row>
                <Row>
                    {(groups?.groupItems || []).map((each: any) =>
                        <Col xxl={12} key={each.id}>
                            <div className='input-group mb-1 w-100'>
                                <button
                                    color='warning'
                                    className={
                                        classNames('btn flex-grow-1 text-start', {
                                            'bg-warning-subtle text-warning': !groups.currentGroup || groups.currentGroup.id !== each.id,
                                            'bg-warning text-white': groups.currentGroup && groups.currentGroup.id === each.id,
                                        })
                                    }
                                    onClick={() => dispatch(selectCurrentGroup(each))}
                                >
                                    <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>{' '}
                                    {each.name}
                                </button>
                                {hasPermissions([Permission.CAMPAIGN_CREATE, Permission.CAMPAIGN_EDIT], profile) && <button
                                    title='Eliminar conjunto de campañas'
                                    className='btn btn-danger'
                                    onClick={() => dispatch(deleteGroup({
                                        storeId: profile.currentStoreId,
                                        groupId: each.id,
                                    }))}
                                >
                                    X
                                </button>}
                            </div>
                        </Col>
                    )}
                </Row>
            </CardBody>
        </Card>
        {groups.showGroupModal && <GroupModal
            show
            toggle={() => showModal(false)}
            onSubmit={(values: any) => {
                dispatch(
                    createGroup({
                        storeId: profile.currentStoreId,
                        params: values
                    })
                )
            }}
        />}
    </React.Fragment>
}

export default GroupMenu
