import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    apiGetReviewConfig,
    apiGetReviews, apiGetReviewsTop, apiImportReviews, apiPatchReview, apiPostReviewConfig
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

export const getReviewList = createAsyncThunk("review/getReviewList", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetReviews(storeId, params);
        return response;
    } catch (error) {
        return error;
    }
});

export const importReviewList = createAsyncThunk("review/importReviewList", async ({ storeId, ...params }: any) => {
    try {
        const response = await apiImportReviews(storeId, params);
        toast.success("Reseñas importadas correctamente", { autoClose: 3000 });
        return response;
    } catch (error) {
        return error;
    }
});

export const getReviewTopList = createAsyncThunk("review/getReviewTopList", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetReviewsTop(storeId, params);
        return response;
    } catch (error) {
        return error;
    }
});

export const getReviewConfig = createAsyncThunk("review/getReviewConfig", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetReviewConfig(storeId)
        return response
    } catch (error) {
        return error
    }
})

export const saveReviewConfig = createAsyncThunk("review/saveReviewConfig", async ({ storeId, config }: any) => {
    try {
        const response = apiPostReviewConfig(storeId, config)
        response.then((r: any) => {
            toast(`Configuración actualizada`, {
                position: "top-right",
                hideProgressBar: false,
                className: 'bg-success text-white',
                progress: undefined,
                toastId: ""
            })
            return r
        })
        return response
    } catch (error) {
        return error
    }
})

export const updateReview = createAsyncThunk("review/updateReview", async ({
    storeId,
    reviewId,
    isPublic,
}: any) => {
    const response = apiPatchReview(storeId, reviewId, {
        is_public: isPublic
    })

    response.then((r: any) => {
        toast(`Reseña actualizada`, {
            position: "top-right",
            hideProgressBar: false,
            className: 'bg-success text-white',
            progress: undefined,
            toastId: ""
        })
        return r
    })
    return response
})