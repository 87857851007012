import React, { useEffect, useRef } from 'react';

//import Components
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, UncontrolledAlert } from 'reactstrap';

import { useNavigate } from 'react-router-dom';

//Import Flatepicker
import TimezoneDropdown from 'Components/Common/TimezoneDropdown';
import TemplateDropdown from 'Components/Templates/TemplateDropdown';
import { Spanish } from "flatpickr/dist/l10n/es";
import { FieldArray, FormikProvider, useFormik } from 'formik';
import moment from 'moment-timezone';
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile, selectReviews, selectTemplate } from 'selectors';
import { getTemplates } from 'slices/templates/thunk';
import TemplateVariableDropdown from 'Components/Templates/TemplateVariableDropdown';
import Select from 'react-select';


const ReviewConfigForm = ({
    onSubmit,
    config,
}: any) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const { currentStoreId } = useSelector(selectProfile)
    const reviews = useSelector(selectReviews)
    const dateRef = useRef<any>(null)
    const defaultRatingMessage = '¿Cómo fue tu experiencia con {{product}}? 🫧\n\n5. ⭐⭐⭐⭐⭐\n4. ⭐⭐⭐⭐\n3. ⭐⭐⭐\n2. ⭐⭐\n1. ⭐'
    const defaultCommentMessage = '¿Cómo fue tu experiencia con {{product}}? 🫧'
    const defaultMediaMessage = 'Y si te animás, agregá una foto o video a tu reseña. Puede ser una foto del producto, de tu piel antes y después, o mostranos tus resultados, lo que vos quieras💕 Si no querés agregar nada, simplemente escribí _saltear_.'
    const defaultThanksMessage = '¡Mil gracias por ayudarnos a mejorar! Como agradecimiento, tenemos un regalo para vos🏹 *10% OFF* con el código🎟️ *REVIEW10* ✨ ¡Esperamos verte de vuelta pronto!'
    const defaultThanksRejectionMessage = '¡Gracias igual por tu tiempo! Ojalá estés disfrutando de los productos 💕✨'

    useEffect(() => {
        if (currentStoreId) {
            dispatch(
                getTemplates({
                    storeId: currentStoreId,
                    all: true,
                })
            )
        }
    }, [currentStoreId])

    const refRating = useRef<HTMLTextAreaElement>(null)
    const refComment = useRef<HTMLTextAreaElement>(null)
    const refMedia = useRef<HTMLTextAreaElement>(null)
    const refThanks = useRef<HTMLTextAreaElement>(null)
    const refRejThanks = useRef<HTMLTextAreaElement>(null)

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            initial_message_id: config?.initial_message_id,
            rating_message: config?.rating_message || defaultRatingMessage,
            comment_message: config?.comment_message || defaultCommentMessage,
            media_message: config?.media_message || defaultMediaMessage,
            thanks_message: config?.thanks_message || defaultThanksMessage,
            thanks_rejection_message: config?.thanks_rejection_message || defaultThanksRejectionMessage,
            review_delay_days: config?.review_delay_days || 30,
            review_gap_days: config?.review_gap_days || 3,
            closing_message_id: config?.closing_message_id,
            auto_publish_ratings: config?.auto_publish_ratings || [4, 5],
            is_enabled: config?.is_enabled || true,
        },
        onSubmit: (values) => {
            onSubmit(values)
        },
    });


    const _clearDate = () => {
        if (dateRef.current) {
            dateRef.current.flatpickr.clear();
            formik.setFieldValue("scheduled_at", null)
            formik.setFieldValue("timezone", null)
        }
    }
    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className="live-preview">
                                    <Form onSubmit={(e: any) => {
                                        e.preventDefault();
                                        formik.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="initial_message_id" className="form-label">Plantilla de mensaje de apertura</Label>
                                                    <p className="text-muted">Debes crear una plantilla de mensaje. La respuesta al primer mensaje debe ser: sí o no o 1 o 2 respectivamente. Típicamente el formato es: "Hola NOMBRE, ¿te gustaría dejar una reseña sobre NOMBRE_DEL_PRODUCTO? Responde 1. Sí 2. No.". Para insertar dinámicamente nombre de producto, nombre de usuario, etc., utiliza las variables.</p>
                                                    <TemplateDropdown
                                                        type="select"
                                                        required
                                                        name="initial_message_id"
                                                        className="form-control"
                                                        value={formik.values.initial_message_id}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.initial_message_id && formik.touched.initial_message_id)}
                                                        selectLabel="-- Seleccione la plantilla de mensaje de apertura de encuesta --"
                                                        showPreviewButton
                                                    />
                                                    {formik.errors.initial_message_id && formik.touched.initial_message_id ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.initial_message_id}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="rating_message" className="form-label">Mensaje de Reseña</Label>
                                                    <Input
                                                        innerRef={refRating}
                                                        type="textarea"
                                                        name="rating_message"
                                                        rows="8"
                                                        className="form-control"
                                                        value={formik.values.rating_message ?? defaultRatingMessage}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.rating_message && formik.touched.rating_message)}
                                                    />
                                                    <br></br>
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refRating.current?.selectionStart
                                                            const nText = formik.values.rating_message.slice(0, position) + `{{${variable}}}` + formik.values.rating_message.slice(position)
                                                            formik.setFieldValue("rating_message", nText)
                                                        }} />
                                                    {formik.errors.rating_message && formik.touched.rating_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.rating_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="comment_message" className="form-label">Mensaje de comentario</Label>
                                                    <Input
                                                        type="textarea"
                                                        innerRef={refComment}
                                                        name="comment_message"
                                                        className="form-control"
                                                        rows="5"
                                                        value={formik.values.comment_message}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.comment_message && formik.touched.comment_message)}
                                                    />
                                                    <br></br>
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refComment.current?.selectionStart
                                                            const nText = formik.values.comment_message.slice(0, position) + `{{${variable}}}` + formik.values.comment_message.slice(position)
                                                            formik.setFieldValue("comment_message", nText)
                                                        }} />
                                                    {formik.errors.comment_message && formik.touched.comment_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.comment_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="media_message" className="form-label">Mensaje multimedia</Label>
                                                    <Input
                                                        type="textarea"
                                                        innerRef={refMedia}
                                                        name="media_message"
                                                        className="form-control"
                                                        rows="5"
                                                        value={formik.values.media_message}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.media_messge && formik.touched.media_messge)}
                                                    />
                                                    <br></br>
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refMedia.current?.selectionStart
                                                            const nText = formik.values.media_message.slice(0, position) + `{{${variable}}}` + formik.values.media_message.slice(position)
                                                            formik.setFieldValue("media_message", nText)
                                                        }} />
                                                    {formik.errors.media_message && formik.touched.media_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.media_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="thanks_message" className="form-label">Mensaje de agradecimiento</Label>
                                                    <Input
                                                        type="textarea"
                                                        rows="5"
                                                        innerRef={refThanks}
                                                        name="thanks_message"
                                                        className="form-control"
                                                        value={formik.values.thanks_message}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.thanks_message && formik.touched.thanks_message)}
                                                    />
                                                    <br></br>
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refThanks.current?.selectionStart
                                                            const nText = formik.values.thanks_message.slice(0, position) + `{{${variable}}}` + formik.values.thanks_message.slice(position)
                                                            formik.setFieldValue("thanks_message", nText)
                                                        }} />
                                                    {formik.errors.thanks_message && formik.touched.thanks_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.thanks_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="thanks_rejection_message" className="form-label">Mensaje de rechazo de reseña</Label>
                                                    <Input
                                                        type="textarea"
                                                        rows="5"
                                                        innerRef={refRejThanks}
                                                        name="thanks_rejection_message"
                                                        className="form-control"
                                                        value={formik.values.thanks_rejection_message}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.thanks_rejection_message && formik.touched.thanks_rejection_message)}
                                                    />
                                                    <br></br>
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refRejThanks.current?.selectionStart
                                                            const nText = formik.values.thanks_rejection_message.slice(0, position) + `{{${variable}}}` + formik.values.thanks_rejection_message.slice(position)
                                                            formik.setFieldValue("thanks_rejection_message", nText)
                                                        }} />
                                                    {formik.errors.thanks_rejection_message && formik.touched.thanks_rejection_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.thanks_rejection_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="closing_message_id" className="form-label">Plantilla de mensaje de agradecimiento</Label>
                                                    <p className="text-muted">Debes crear una plantilla de mensaje idéntica al mensaje de agradecimiento. Solamente se envía cuando la persona no llegó a completar toda la encuesta pero dejó mínimamente la puntuación. Típicamente el formato es: "¡Mil gracias por ayudarnos a mejorar! Como agradecimiento, tenemos un regalo para vos🏹 *10% OFF* con el código🎟️ *REVIEW10* ✨ ¡Esperamos verte de vuelta pronto!". Para insertar dinámicamente nombre de producto, nombre de usuario, etc., utiliza las variables.</p>
                                                    <TemplateDropdown
                                                        type="select"
                                                        required
                                                        name="closing_message_id"
                                                        className="form-control"
                                                        value={formik.values.closing_message_id}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.closing_message_id && formik.touched.closing_message_id)}
                                                        selectLabel="-- Seleccione la plantilla de mensaje de apertura de encuesta --"
                                                        showPreviewButton
                                                    />
                                                    {formik.errors.closing_message_id && formik.touched.closing_message_id ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.closing_message_id}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="review_delay_days" className="form-label">Enviar luego de X días desde la fecha de compra</Label>
                                                    <Input
                                                        type="number"
                                                        name="review_delay_days"
                                                        className="form-control"
                                                        multiple
                                                        value={formik.values.review_delay_days}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.review_delay_days && formik.touched.review_delay_days)}
                                                    />
                                                    {formik.errors.review_delay_days && formik.touched.review_delay_days ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.review_delay_days}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="review_gap_days" className="form-label">Si el pedido tiene más de un producto, ¿cuántos días deben pasar entre reseñas?</Label>
                                                    <Input
                                                        type="number"
                                                        name="review_gap_days"
                                                        className="form-control"
                                                        multiple
                                                        value={formik.values.review_gap_days}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.review_gap_days && formik.touched.review_gap_days)}
                                                    />
                                                    {formik.errors.review_gap_days && formik.touched.review_gap_days ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.review_gap_days}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="auto_publish_ratings" className="form-label">
                                                        Auto-publicar reseñas con calificación
                                                    </Label>
                                                    <Select
                                                        isMulti
                                                        name="auto_publish_ratings"
                                                        value={[1, 2, 3, 4, 5]
                                                            .filter(rating => formik.values.auto_publish_ratings.includes(rating))
                                                            .map(rating => ({
                                                                value: rating,
                                                                label: `${rating} ${rating === 1 ? 'Estrella' : 'Estrellas'} ${'⭐'.repeat(rating)}`
                                                            }))}
                                                        options={[
                                                            { value: 1, label: '1 Estrella ⭐' },
                                                            { value: 2, label: '2 Estrellas ⭐⭐' },
                                                            { value: 3, label: '3 Estrellas ⭐⭐⭐' },
                                                            { value: 4, label: '4 Estrellas ⭐⭐⭐⭐' },
                                                            { value: 5, label: '5 Estrellas ⭐⭐⭐⭐⭐' }
                                                        ]}
                                                        onChange={(selectedOptions: any) => {
                                                            const values = selectedOptions
                                                                ? (selectedOptions as Array<{ value: number }>).map(option => option.value)
                                                                : [];
                                                            formik.setFieldValue('auto_publish_ratings', values);
                                                        }}
                                                        onBlur={() => formik.setFieldTouched('auto_publish_ratings', true)}
                                                        className={
                                                            formik.errors.auto_publish_ratings &&
                                                                formik.touched.auto_publish_ratings
                                                                ? 'is-invalid'
                                                                : ''
                                                        }
                                                        styles={{
                                                            control: (base: any) => ({
                                                                ...base,
                                                                borderColor: formik.errors.auto_publish_ratings &&
                                                                    formik.touched.auto_publish_ratings
                                                                    ? '#dc3545'
                                                                    : base.borderColor
                                                            })
                                                        }}
                                                    />
                                                    {formik.errors.auto_publish_ratings && formik.touched.auto_publish_ratings ? (
                                                        <FormFeedback type="invalid" className='d-block'>
                                                            {formik.errors.auto_publish_ratings}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <FormGroup
                                                        check
                                                        inline
                                                    >
                                                        <Input
                                                            type="checkbox"
                                                            name="is_enabled"
                                                            checked={formik.values.is_enabled}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            invalid={!!(formik.errors.is_enabled && formik.touched.is_enabled)}
                                                        />
                                                        <Label htmlFor="review_delay_days" className="form-label">Activar funcionalidad de reseñas</Label>
                                                    </FormGroup>
                                                    {formik.errors.is_enabled && formik.touched.is_enabled ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.is_enabled}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="text-end">
                                                    <button type="button" className="btn btn-light mr-2" onClick={() => navigate('/reviews/')}>Cancelar</button>{' '}
                                                    <button type="submit" className="btn btn-primary" disabled={reviews.status === 'loading'}>
                                                        {reviews.status === 'loading' && <span className="d-flex align-items-center">
                                                            <span className="flex-grow-1 me-2">
                                                                Cargando...
                                                            </span>
                                                            <Spinner size="sm" className="flex-shrink-0" role="status"> Cargando... </Spinner>
                                                        </span>}{' '}
                                                        {reviews.status !== 'loading' && 'Guardar'}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment >
    )
};

export default ReviewConfigForm;
