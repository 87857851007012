import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectProfile } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';

interface CampaignStatusDropDownProps {
    placeholder: string
    onSelect: (campaign: any) => void
}

const STATUSES: { [key: string]: string } = {
    'CREATED': 'Pendiente',
    'ABANDONED': 'Abandonado',
    'CONVERTED': 'Convertido',
    'CONFIRMED': 'Orgánico',
    'PAYMENT_PENDING': 'Pendiente de pago',
}

const CampaignStatusDropDown = ({ placeholder, onSelect }: CampaignStatusDropDownProps) => {
    let displayName;
    const dispatch: any = useDispatch()
    const profile = useSelector(selectProfile)
    const [selectedStatus, setSelectedStatus] = useState<any>(null)

    const _onSelect = (selected: any) => {
        onSelect(selected)
        setSelectedStatus(selected)
    }

    if (selectedStatus) {
        displayName = <span>Estado actual: <b>{STATUSES[selectedStatus]}</b></span>
    } else {
        displayName = placeholder || 'Seleccione un estado de pedido'
    }

    return (
        <ButtonGroup>
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="dropdown-btn btn btn-primary" role="button">
                    <i className='mdi mdi-filter'></i> {displayName} <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => _onSelect(null)} className="text-center text-muted">Eliminar filtro</DropdownItem>
                    {(Object.keys(STATUSES)).map((each: any, key: any) => <DropdownItem key={key} onClick={() => _onSelect(each)}>{STATUSES[each]}</DropdownItem>)}
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
    );
};

export default CampaignStatusDropDown;
