import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectProfile } from 'selectors';
import { setCurrentStore } from 'slices/thunks';

interface StoreDropDownProps {
    placeholder: string
}

const StoreDropDown = ({ placeholder }: StoreDropDownProps) => {
    const profile = useSelector(selectProfile)
    const dispatch: any = useDispatch()
    let displayName;

    if (profile && profile.currentStoreId) {
        displayName = <span>Tienda: <b>{profile.currentStoreName}</b></span>
    } else {
        displayName = placeholder ?? 'Seleccione una tienda'
    }
    const onSelect = (id: any) => {
        dispatch(setCurrentStore(id))
    }
    return (
        <ButtonGroup className='m-2'>
            <UncontrolledDropdown>
                <DropdownToggle tag="button" className='btn btn-light'>
                    <i className="mdi mdi-storefront-outline"></i> {displayName} <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Tiendas habilitadas</DropdownItem>
                    {(profile.user?.store_permissions || []).map((each: any, key: any) =>
                        <DropdownItem key={key} onClick={() => onSelect(each.store.uid)}>{each.store.name}</DropdownItem>
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
    );
};

export default StoreDropDown;