
export enum Permission {
    REVIEW_CONFIG = "REVIEW_CONFIG",
    REVIEW_VIEW = "REVIEW_VIEW",
    REVIEW_APPROVE = "REVIEW_APPROVE",
    METRICS = "METRICS",
    CAMPAIGN_VIEW = "CAMPAIGN_VIEW",
    CAMPAIGN_CREATE = "CAMPAIGN_CREATE",
    CAMPAIGN_EDIT = "CAMPAIGN_EDIT",
    CAMPAIGN_DELETE = "CAMPAIGN_DELETE",
    TEMPLATE_VIEW = "TEMPLATE_VIEW",
    TEMPLATE_CREATE = "TEMPLATE_CREATE",
    TEMPLATE_DELETE = "TEMPLATE_DELETE",
    STORE_ORDERS = "STORE_ORDERS",
    STORE_SETTINGS = "STORE_SETTINGS",
    CRM_CHAT = "CRM_CHAT",
    CRM_ASSISTANT = "CRM_ASSISTANT",
    CRM_TICKET = "CRM_TICKET",
}

export const isOwner = (state: any) => state.currentStoreRole == 'OWNER'

export const hasPermissions = (permissions: string[], state: any) => (
    isOwner(state) || permissions.some(perm => state.currentStorePermissions.indexOf(perm) !== -1)
)
