import CampaignDropDown from 'Components/Campaigns/CampaignDropDown';
import CampaignStatusDropDown from 'Components/Campaigns/CampaignStatusDropDown';
import CampaignTable from 'Components/Campaigns/CampaignTable';
import BreadCrumb from 'Components/Common/BreadCrumb';
import Pagination from 'Components/Common/Pagination';
import useCurrentStore from 'Components/Hooks/CurrentStore';
import OrderTable from 'Components/Orders/OrderTable';
import { Permission, hasPermissions } from 'common/security';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap';
import { selectCampaigns, selectOrder, selectProfile } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import { getOrderList } from 'slices/order/thunk';

const OrderList = () => {
    useCurrentStore()
    const dispatch: any = useDispatch()
    const profile = useSelector(selectProfile)
    const [openFilters, setOpenFilters] = useState(false)
    const [campaignId, setCampaignId] = useState<any>(null)
    const [status, setStatus] = useState<any>(null)
    const campaigns = useSelector(selectCampaigns)
    const orders = useSelector(selectOrder)

    const fetchPage = useCallback((page: any) => {
        if (profile.currentStoreId && hasPermissions([Permission.STORE_ORDERS], profile)) {
            dispatch(
                getOrderList({
                    storeId: profile.currentStoreId,
                    skip: page,
                    campaign_id: campaignId,
                    status,
                })
            )
        }
    }, [profile.currentStoreId, campaignId, status])

    useEffect(() => {
        if (profile.currentStoreId) {
            fetchPage(0)
        }
    }, [profile.currentStoreId]);


    useEffect(() => {
        if (profile.currentStoreId) {
            fetchPage(0)
        }
    }, [profile.currentStoreId, campaignId, status]);

    document.title = 'Todos los pedidos | Burbuxa'
    const filters = <>
        <CampaignStatusDropDown placeholder='Filtrar por estado del pedido' onSelect={(c) => setStatus(c)} />
        {hasPermissions([Permission.CAMPAIGN_VIEW, Permission.CAMPAIGN_CREATE, Permission.CAMPAIGN_EDIT], profile) &&
            <CampaignDropDown placeholder='Filtrar por campaña' onSelect={(c) => setCampaignId(c ? c.id : null)} value={campaignId} />}
    </>

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Listar órdenes" pageTitle="Órdenes" />

                <Container fluid>
                    <Row className="mb-3 pb-1 d-block d-lg-none">
                        <Row>
                            <Col className='text-end m-0 mb-2 p-0'>
                                <Button color='info' onClick={() => setOpenFilters(true)}>
                                    <i className='ri-filter-line'></i>
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                    <Offcanvas isOpen={openFilters} toggle={() => setOpenFilters(false)} direction="end">
                        <OffcanvasHeader toggle={() => setOpenFilters(false)} id="offcanvasRightLabel">
                            Filtros de órdenes
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <div className="d-flex align-items-lg-center flex-lg-row flex-column" style={{ gap: '10px' }}>
                                {filters}
                            </div>
                        </OffcanvasBody>
                    </Offcanvas>
                    <Row className="mb-3 pb-1 d-none d-lg-block">
                        <Col xxl={12} className="text-end mb-2 d-flex justify-content-end gap-2">
                            {filters}
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <CardBody className="p-0">
                                    <OrderTable loading={orders.status == 'loading'} orders={orders.orderItems} showTitle={false} />
                                    <Pagination
                                        total={orders.limit !== 0 ? Math.ceil(orders.total / orders.limit) : 0}
                                        data={orders.orderItems}
                                        setCurrentPage={(page: any) => fetchPage((page - 1) * orders.limit)}
                                        currentPage={orders.limit !== 0 ? Math.ceil(orders.skip / orders.limit) + 1 : 0}
                                        perPageData={orders.limit}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    </React.Fragment>
}

export default OrderList 
