import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { selectProfile } from "selectors";
import PermissionField from "./PermissionField";
import { Label, Input, Button, Modal, ModalHeader, ModalBody, Container, ModalFooter } from "reactstrap";

const UserDialog = (props: any) => {
    const profile = useSelector(selectProfile);

    // Define esquema de validación
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required("El nombre es obligatorio.")
            .min(2, "El nombre debe tener al menos 2 caracteres."),
        last_name: Yup.string()
            .required("El apellido es obligatorio.")
            .min(2, "El apellido debe tener al menos 2 caracteres."),
        email: Yup.string()
            .required("El email es obligatorio.")
            .email("Debe ser un email válido."),
        role: Yup.string()
            .required("Debe seleccionar un rol."),
        permissions: Yup.array()
            .when("role", {
                is: "MEMBER",
                then: (schema) => schema.min(1, "Debe asignar al menos un permiso.").required("Debe asignar permisos."),
            }),
    });

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: props.current ? props.current.first_name : "",
            last_name: props.current ? props.current.last_name : "",
            email: props.current ? props.current.email : "",
            role: props.current ? props.current.role : "",
            permissions: props.current ? props.current.permissions : [],
        },
        validationSchema, // Vincula el esquema de validación
        onSubmit: (values) => {
            props.onSubmit(values)
        },
    });

    return (
        <Modal isOpen={props.isOpen} toggle={() => props.toggle()}>
            <ModalHeader toggle={() => props.toggle()}>{props.current ? 'Actualizar' : 'Agregar'} usuario</ModalHeader>
            <ModalBody>
                <form
                    id="frmUser"
                    name="frmUser"
                    onSubmit={formik.handleSubmit}
                >
                    <Container>
                        <div className="mb-3">
                            <Label htmlFor="first_name" className="form-label">
                                Nombre
                            </Label>
                            <Input
                                type="text"
                                name="first_name"
                                placeholder="Ingrese el nombre"
                                onChange={formik.handleChange}
                                value={formik.values.first_name}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.first_name && formik.errors.first_name && (
                                <small className="text-danger">{formik.errors.first_name}</small>
                            )}
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="last_name" className="form-label">
                                Apellido
                            </Label>
                            <Input
                                type="text"
                                name="last_name"
                                placeholder="Ingrese el apellido"
                                onChange={formik.handleChange}
                                value={formik.values.last_name}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.last_name && formik.errors.last_name && (
                                <small className="text-danger">{formik.errors.last_name}</small>
                            )}
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="email" className="form-label">
                                E-mail
                            </Label>
                            <Input
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                placeholder="Ingrese el email"
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <small className="text-danger">{formik.errors.email}</small>
                            )}
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="role" className="form-label">
                                Rol del usuario
                            </Label>
                            <Input
                                type="select"
                                name="role"
                                onChange={formik.handleChange}
                                value={formik.values.role}
                                onBlur={formik.handleBlur}
                            >
                                <option value="">-- Seleccione --</option>
                                <option value="MEMBER">Usuario</option>
                                <option value="OWNER">Propietario</option>
                            </Input>
                            {formik.touched.role && formik.errors.role && (
                                <small className="text-danger">{formik.errors.role}</small>
                            )}
                            <div>
                                <small className="text-muted">
                                    Si selecciona <b>Usuario</b> deber&aacute; asignar permisos para su ingreso.
                                </small>
                            </div>
                        </div>
                        {formik.values.role === "MEMBER" && (
                            <>
                                <PermissionField name="permissions" formik={formik} />
                            </>
                        )}
                    </Container>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" form="frmUser" color="success">
                    <i className="ri-add-line"></i> {props.current ? 'Actualizar' : 'Agregar'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserDialog;
