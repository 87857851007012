import useCurrentStore from "Components/Hooks/CurrentStore"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Button, Col, Container, Row } from "reactstrap"
import { selectProfile, selectUsers } from "selectors"
import { addUserToStore, deleteUserToStore, getUsers, updateUserToStore } from "slices/thunks"
import UserDialog from "./UserDialog"
import UserTable from "./UserTable"
import { UserAction } from "./UserActionDropDown"


const UserManager = () => {
    useCurrentStore()
    const users = useSelector(selectUsers)
    const profile = useSelector(selectProfile)
    const [open, setOpen] = useState(false)
    const dispatch: any = useDispatch()
    const [current, setCurrent] = useState<any>(null)
    const fetchUsers = useCallback(
        () => {
            if (profile.currentStoreId) {
                dispatch(
                    getUsers({
                        storeId: profile.currentStoreId,
                    })
                )
            }
        }, [profile.currentStoreId]
    )

    const deleteUser = useCallback((userId: string) => {
        if (profile.currentStoreId) {
            dispatch(
                deleteUserToStore({
                    storeId: profile.currentStoreId,
                    userId,
                })
            ).then(() => {
                toast.success('Usuario eliminado correctamente')
                fetchUsers()
            })
        }
    }, [profile.currentStoreId])

    const updateUser = useCallback((values: any) => {
        if (profile.currentStoreId && current) {

        }
    }, [profile.currentStoreId, current?.uid])

    useEffect(() => {
        if (profile.currentStoreId) {
            fetchUsers()
        }
    }, [profile.currentStoreId])

    const onSubmit = useCallback(
        (values: any) => {
            if (profile.currentStoreId) {
                if (current !== null) {
                    dispatch(
                        updateUserToStore({
                            storeId: profile.currentStoreId,
                            userId: current.uid,
                            ...values
                        })
                    ).then(() => {
                        toast.success('Usuario actualizado correctamente')
                        fetchUsers()
                        setOpen(false)
                    })
                } else {
                    dispatch(
                        addUserToStore({
                            storeId: profile.currentStoreId,
                            ...values,
                        })
                    ).then(() => {
                        toast.success('Usuario autorizado correctamente')
                        fetchUsers()
                        setOpen(false)
                    })
                }
            }
        }, [profile.currentStoreId, current]
    )

    return <Container fluid>
        <Row>
            <Col xxl={12} style={{
                textAlign: 'right',
            }}>
                <Button color="primary" onClick={() => setOpen(true)}>
                    <i className="fs-13 align-middle ri-add-line"></i>{' '}Agregar usuario
                </Button>
            </Col>
            <Col xxl={12}>
                <UserTable
                    onAction={(action: UserAction, user) => {
                        if (action === UserAction.EDIT) {
                            setCurrent(user)
                            setOpen(true)
                        } else {
                            deleteUser(user.uid)
                        }
                    }}
                    users={users.users}
                />
            </Col>
        </Row>
        <Row>
            <Col xxl={12}>
                <UserDialog
                    toggle={() => {
                        setCurrent(null)
                        setOpen(!open)
                    }}
                    current={current}
                    isOpen={open}
                    onSubmit={onSubmit}
                />
            </Col>
        </Row>
    </Container>
}

export default UserManager