
interface FileValidatorProps {
    selector: string
    validateRow: (index: number, row: any) => boolean
    onError: (errors: string[]) => void
    onSuccess: (values: string[][]) => void
}

export class FileValidationError extends Error {
}

export const CSVFileValidator = (props: FileValidatorProps) => {
    const validator = () => {
        const el = document.querySelector(props.selector) as HTMLInputElement
        const reader = new FileReader()

        reader.onload = () => {
            const result = reader.result?.toString()
            const lines = result?.split('\n')

            if (!lines) {
                props.onError(['Error, archivo vacio.'])
                return
            }

            const errors: string[] = []
            const values: string[][] = []

            for (let i = 1; i < lines.length; i++) {
                try {
                    const line = lines[i].trim()
                    if (line) {
                        const row = line.split(';')
                        props.validateRow(i, row)
                        values.push(row)
                    }
                } catch (e: any) {
                    errors.push(e.message)
                }
            }

            if (errors.length > 0) {
                props.onError(errors)
            } else {
                props.onSuccess(values)
            }
        }

        if (el.files && el.files.length > 0) {
            reader.readAsBinaryString(el.files[0])
        }
    }
    return validator
}

export function isDateValid(dateStr: string) {
    const date = new Date(dateStr);
    return !isNaN(date.getTime())
}