import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectCampaigns, selectGroup, selectProfile } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import { getGroups } from 'slices/groups/thunk';

interface GroupDropDownProps {
    placeholder: string
    onSelect: (group: any) => void
    value: any
}

const GroupDropDown = ({ placeholder, onSelect, value }: GroupDropDownProps) => {
    let displayName;
    const dispatch: any = useDispatch()
    const profile = useSelector(selectProfile)
    const groups = useSelector(selectGroup)
    const [selectedGroup, setSelectedGroup] = useState<any>(value)

    useEffect(() => {
        if (profile.currentStoreId) {
            dispatch(
                getGroups({
                    storeId: profile.currentStoreId
                })
            )
        }
    }, [profile.currentStoreId])

    const _onSelect = (selected: any) => {
        onSelect(selected)
        setSelectedGroup(selected)
    }

    if (selectedGroup) {
        displayName = <span>Conjunto de campañas: <b>{(groups.groupItems || []).find((each: any) => each.id == selectedGroup)?.name}</b></span>
    } else {
        displayName = placeholder || 'Seleccione un conjunto de campañas'
    }

    return (
        <ButtonGroup>
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="dropdown-btn btn btn-primary" role="button">
                    <i className='mdi mdi-filter'></i> {displayName} <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => _onSelect(null)} className="text-center text-muted">Eliminar filtro</DropdownItem>
                    <DropdownItem header>Conjuntos de campaña de {profile.currentStoreName}</DropdownItem>
                    {(groups.groupItems || []).map((each: any, key: any) => <DropdownItem key={key} active={selectedGroup?.id == each.id} onClick={() => _onSelect(each)}>{each.name}</DropdownItem>)}
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
    );
};

export default GroupDropDown;
