import { createSelector } from "reselect";


export const selectCampaigns = createSelector(
    (state: any) => state.Campaign,
    (campaigns: any) => campaigns
)

export const selectProfile = createSelector(
    (state: any) => state.Profile,
    (profile: any) => profile
)

export const selectOrder = createSelector(
    (state: any) => state.Order,
    (order: any) => order
)

export const selectMessage = createSelector(
    (state: any) => state.Message,
    (message: any) => message
)

export const selectStore = createSelector(
    (state: any) => state.Store,
    (store: any) => store
)

export const selectTemplate = createSelector(
    (state: any) => state.Template,
    (template: any) => template
)

export const selectGroup = createSelector(
    (state: any) => state.Groups,
    (group: any) => group
)

export const selectReviews = createSelector(
    (state: any) => state.Reviews,
    (review: any) => review,
)

export const selectChat = createSelector(
    (state: any) => state.Chat,
    (chat: any) => chat
);

export const selectStoreSettings = createSelector(
    (state: any) => state.Store.settings, // Use lowercase 'store'
    (settings: any) => settings
);

export const selectStoreLoading = createSelector(
    (state: any) => state.Store.loading,
    (loading: any) => loading
);

export const selectStoreError = createSelector(
    (state: any) => state.Store.error,
    (error: any) => error
);

export const selectConnectWhatsAppLoading = createSelector(
    (state: any) => state.Store.connectWhatsAppLoading,
    (loading: any) => loading
);

export const selectConnectWhatsAppError = createSelector(
    (state: any) => state.Store.connectWhatsAppError,
    (error: any) => error
);

export const selectConnectWhatsAppSuccess = createSelector(
    (state: any) => state.Store.connectWhatsAppSuccess,
    (success: any) => success
);

export const selectAssistant = createSelector(
    (state: any) => state.Assistant,
    (assistant: any) => assistant
);

export const selectCrm = createSelector(
    (state: any) => state.Crm,
    (crm: any) => crm
);

export const selectTickets = createSelector(
    (state: any) => state.Tickets,
    (tickets: any) => tickets
);

export const selectUsers = createSelector(
    (state: any) => state.Users,
    (users: any) => users
);

export const selectBillingConfiguration = createSelector(
    (state: any) => state.Store.settings?.billing_configuration,
    (billingConfiguration: any) => billingConfiguration
);

export const selectUpdatingBillingConfiguration = createSelector(
    (state: any) => state.Store.updatingBillingConfiguration,
    (updating: any) => updating
);

export const selectBillingConfigurationError = createSelector(
    (state: any) => state.Store.billingConfigurationError,
    (error: any) => error
);