import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectCampaigns, selectProfile } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import { ICampaign } from 'types/Dashboard';

interface CampaignDropDownProps {
    placeholder: string
    onSelect: (campaign: any) => void
    value: any
}

const CampaignDropDown = ({ placeholder, onSelect, value }: CampaignDropDownProps) => {
    let displayName;
    const dispatch: any = useDispatch()
    const profile = useSelector(selectProfile)
    const campaigns = useSelector(selectCampaigns)
    const [selectedCampaign, setSelectedCampaign] = useState<any>(value)

    useEffect(() => {
        if (profile.currentStoreId) {
            dispatch(
                getCampaigns({
                    storeId: profile.currentStoreId,
                    all: true,
                    system_only: false,
                })
            )
        }
    }, [profile.currentStoreId])

    const _onSelect = (selected: any) => {
        onSelect(selected)
        setSelectedCampaign(selected)
    }

    if (selectedCampaign) {
        displayName = <span>Campaña actual: <b>{selectedCampaign.name}</b></span>
    } else {
        displayName = placeholder || 'Seleccione una campaña'
    }

    return (
        <ButtonGroup>
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="dropdown-btn btn btn-primary" role="button">
                    <i className='mdi mdi-filter'></i> {displayName} <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => _onSelect(null)} className="text-center text-muted">Eliminar filtro</DropdownItem>
                    <DropdownItem header>Campañas de {profile.currentStoreName}</DropdownItem>
                    {(campaigns.campaignItems || []).map((each: any, key: any) => <DropdownItem active={selectedCampaign?.id == each.id} key={key} onClick={() => _onSelect(each)}>{each.name}</DropdownItem>)}
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
    );
};

export default CampaignDropDown;
