import CampaignDropDown from 'Components/Campaigns/CampaignDropDown';
import CampaignStatusDropDown from 'Components/Campaigns/CampaignStatusDropDown';
import CampaignTable from 'Components/Campaigns/CampaignTable';
import BreadCrumb from 'Components/Common/BreadCrumb';
import Pagination from 'Components/Common/Pagination';
import useCurrentStore from 'Components/Hooks/CurrentStore';
import OrderTable from 'Components/Orders/OrderTable';
import ReviewFilter from 'Components/Reviews/ReviewFilter';
import ReviewImportModal from 'Components/Reviews/ReviewImportModal';
import ReviewTable from 'Components/Reviews/ReviewTable';
import { reviews } from 'common/data';
import { apiExportReviews } from 'helpers/backend_helper';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { selectCampaigns, selectOrder, selectProfile, selectReviews } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import { getOrderList } from 'slices/order/thunk';
import { getReviewList, importReviewList, updateReview } from 'slices/reviews/thunk';


const ReviewList = () => {
    useCurrentStore()
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const [filter, setFilter] = useState({
        rate: null,
        date: null,
        product_id: null
    })
    const [campaignId, setCampaignId] = useState<any>(null)
    const [status, setStatus] = useState<any>(null)
    const campaigns = useSelector(selectCampaigns)
    const reviews = useSelector(selectReviews)
    const [showImport, setShowImport] = useState(false)

    const fetchPage = useCallback((page: any) => {
        if (currentStoreId) {
            dispatch(
                getReviewList({
                    storeId: currentStoreId,
                    skip: page,
                    ...filter,
                })
            )
        }
    }, [currentStoreId, ...Object.values(filter)])

    useEffect(() => {
        fetchPage(0)
    }, [currentStoreId, ...Object.values(filter)]);

    const onUpdateReview = useCallback((revId: number, isPublic: boolean) => {
        dispatch(
            updateReview({
                storeId: currentStoreId,
                reviewId: revId,
                isPublic
            })
        )
    }, [currentStoreId])

    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
        }
    }, [currentStoreId]);


    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
        }
    }, [currentStoreId]);

    const importReviews = useCallback((values: any) => {
        dispatch(
            importReviewList({
                storeId: currentStoreId,
                reviews: values
            })
        ).then(() => {
            fetchPage(0)
        })
        setShowImport(false)
    }, [currentStoreId])

    const onExport = useCallback((filters: any) => {
        apiExportReviews(currentStoreId, filters).then(
            (res: any) => {
                console.log(res, res.data)
                const blob = res.data;

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(res);
                link.download = 'review.csv';
                link.click();
            })
            .catch((error: any) => {
                console.error('Error fetching the file:', error);
            });
    }, [currentStoreId]);

    document.title = 'Ver Reseñas | Burbuxa'

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Listado de Reseñas" pageTitle="Reseñas" />

                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <ReviewFilter
                                setFilter={setFilter}
                                filter={filter}
                                onExport={onExport}
                                onImport={() => setShowImport(true)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="p-0">
                                    <ReviewTable
                                        update={(reviewId, isPublic) => onUpdateReview(reviewId, isPublic)}
                                        loading={reviews.status == 'loading'}
                                        reviews={reviews.items}
                                        showTitle={false}
                                    />
                                    <Pagination
                                        total={reviews.limit !== 0 ? Math.ceil(reviews.total / reviews.limit) : 0}
                                        data={reviews.items}
                                        setCurrentPage={(page: any) => fetchPage((page - 1) * reviews.limit)}
                                        currentPage={reviews.limit !== 0 ? Math.ceil(reviews.skip / reviews.limit) + 1 : 0}
                                        perPageData={reviews.limit}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
        <ReviewImportModal
            isOpen={showImport}
            close={() => setShowImport(false)}
            onImport={(values) => importReviews(values)}
        />
    </React.Fragment>
}

export default ReviewList 
