import { CSVFileValidator, FileValidationError, isDateValid } from "common/validator"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { selectReviews } from "selectors"

interface ReviewImportModalProps {
    isOpen: boolean
    onImport: (values: any) => void
    close: () => void
}

const ReviewImportModal = (props: ReviewImportModalProps) => {
    const reviews = useSelector(selectReviews)

    const validator = CSVFileValidator({
        onError: (errors: string[]) => {
            toast.error('No se pudieron importar las reseñas debido a los siguientes errores')
            for (let error of errors) {
                toast.error(error)
            }
        },
        onSuccess: (values: string[][]) => {
            props.onImport(values.map((each) => ({
                date: each[5],
                media_url: each[4],
                rating: each[2],
                comment: each[3],
                user_name: each[1],
                product_id: each[0],
            })))
        },
        selector: '[data-csv-import]',
        validateRow: (index, row) => {
            if (row.length !== 6) {
                throw new FileValidationError(`La fila ${index} no tiene la cantidad necesaria de campos: Product Id; Customer Name; Rating; Comment; Media Url; Date.`)
            }

            const rating = parseInt(row[2])
            if (!rating || rating > 5 || rating < 1) {
                throw new FileValidationError(`La fila ${index} no tiene un rating válido (entre 1 y 5 incluido).`)
            }

            const date = row[5]
            if (!isDateValid(date)) {
                throw new FileValidationError(`La fila ${index} no tiene una fecha válida (Año completo-mes-día).`)
            }

            return true
        }
    })

    const onSubmit = () => {
        validator()
    }

    return <Modal isOpen={props.isOpen} toggle={() => props.close()}>
        <ModalHeader toggle={() => props.close()}>
            Importar reseñas
        </ModalHeader>
        <ModalBody>
            <Container style={{
                overflow: 'auto'
            }}>
                <Row>
                    <Col xxl={12}>
                        <p>El formato del archivo debe ser .csv y contener las siguientes columnas:</p>
                        <ul>
                            <li>PRODUCT ID (ID del producto en el ecommerce)</li>
                            <li>CUSTOMER NAME</li>
                            <li>RATING (del 1 al 5)</li>
                            <li>COMMENT</li>
                            <li>MEDIA URL (Una url opcional)</li>
                            <li>DATE (Con el formato: Año completo-mes-dia)</li>
                        </ul>
                        <p>El csv debe estar separado por ";".</p>
                    </Col>
                    <Col>
                        <Input type="file"
                            name="csv"
                            accept=".csv"
                            data-csv-import
                        />
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={() => onSubmit()} disabled={reviews.status == 'loading'}>
                {reviews.status == 'loading' ? 'Importando...' : 'Importar'}
            </Button>
        </ModalFooter>
    </Modal>
}

export default ReviewImportModal