import DisplayDataCard from 'Components/Common/DisplayDataCard'
import OrderTable from 'Components/Orders/OrderTable'
import React from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import StoreMetricsLoading from './StoreMetricsLoading'
import { selectProfile } from 'selectors'
import { useSelector } from 'react-redux'
import { Permission, hasPermissions } from 'common/security'


const StoreMetrics = ({ currency, report, orderItems }: any) => {
    const profile = useSelector(selectProfile)

    if (!report) {
        return <StoreMetricsLoading />
    }

    if (!hasPermissions([Permission.METRICS], profile)) {
        return <></>
    }

    return <React.Fragment>
        <Container fluid>
            <Row>
                <Col>
                    <div className="h-100">
                        <Row>
                            <Col sm={6} md={3}>
                                <DisplayDataCard
                                    value={currency == 'USD' ? report?.totalRecoveredVolume : report?.totalRecoveredVolumeStore}
                                    label='Facturación'
                                    bgIconColor="secondary"
                                    icon="bx bx-dollar-circle"
                                    prefix={currency == 'USD' ? 'USD ' : profile.currentStoreCurrency}
                                />
                            </Col>
                            <Col sm={6} md={3}>
                                <DisplayDataCard
                                    value={report?.roi}
                                    label='Retorno de inversión'
                                    suffix=" %"
                                    bgIconColor="primary"
                                    icon="bx bx-line-chart"
                                />
                            </Col>
                            <Col sm={6} md={3}>
                                <DisplayDataCard
                                    value={report?.totalConversionRate}
                                    label='Tasa de conversión'
                                    suffix=" %"
                                    bgIconColor="success"
                                    icon="bx bx-meteor"
                                />
                            </Col>
                            <Col sm={6} md={3}>
                                <DisplayDataCard
                                    value={report?.totalClientsReached}
                                    label='Clientes únicos contactados'
                                    bgIconColor="info"
                                    icon="bx bx-user-circle" />
                            </Col>
                            <Col sm={6} md={4}>
                                <DisplayDataCard
                                    value={report?.totalRecoveredCarts}
                                    label='Pedidos convertidos'
                                    bgIconColor="warning"
                                    icon="bx bxs-cart"
                                />
                            </Col>
                            <Col sm={6} md={4}>
                                <DisplayDataCard
                                    value={report?.totalMessagesSent}
                                    label='Mensajes enviados'
                                    bgIconColor="danger"
                                    icon="bx bx-message-dots"
                                />
                            </Col>
                            <Col sm={6} md={4}>
                                <DisplayDataCard
                                    value={currency == 'USD' ? report?.totalInvestment : report?.totalInvestmentStore}
                                    label='Inversión'
                                    prefix={currency == 'USD' ? 'USD ' : profile.currentStoreCurrency}
                                    bgIconColor="warning"
                                    icon="bx bx-check-circle"
                                />
                            </Col>
                            <Col sm={12} md={12}>

                                <Card>
                                    <CardHeader className="align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Pedidos recientes</h4>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </CardHeader>
                                    <CardBody className='p-0'>
                                        <OrderTable
                                            orders={orderItems}
                                            title="Pedidos convertidos recientemente"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    </React.Fragment>
}

export default StoreMetrics 
