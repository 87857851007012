import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    apiAddUserStore,
    apiDeleteUserStore,
    apiGetUsers,
    apiUpdateUserStore,
} from "../../helpers/backend_helper";


export const getUsers = createAsyncThunk("users/getUsersList", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetUsers({ storeId, ...params });
        return response;
    } catch (error) {
        return error;
    }
});

export const addUserToStore = createAsyncThunk("users/AddUserStore", async ({ storeId, ...params }: any) => {
    try {
        const response = apiAddUserStore({ storeId, ...params });
        return response;
    } catch (error) {
        return error;
    }
});

export const deleteUserToStore = createAsyncThunk("users/DeleteUserStore", async ({ storeId, userId }: any) => {
    try {
        const response = apiDeleteUserStore({ storeId, userId });
        return response;
    } catch (error) {
        return error;
    }
});

export const updateUserToStore = createAsyncThunk("users/DeleteUserStore", async ({ storeId, userId, ...params }: any) => {
    try {
        const response = apiUpdateUserStore({ storeId, userId, ...params });
        return response;
    } catch (error) {
        return error;
    }
});