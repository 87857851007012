import { formatIntNumber, formatNumber } from "helpers/format";
import { useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row, UncontrolledAlert } from "reactstrap";


const CampaignModal = ({
    toggle,
    title,
    isOpen,
    budget,
    onActivate,
    onSave,
}: any) => {
    return <Modal
        fade={true}
        isOpen={isOpen}
        toggle={() => {
            if (toggle) {
                toggle();
            }
        }}
    >
        <ModalBody>
            <form action="#">
                <Row className="row g-3 p-2">
                    <Col sm={12} className="text-center">
                        <i className="bx bx-calculator display-5 text-primary"></i>
                        <h4 className="mb-2 mt-4">{title}</h4>
                    </Col>
                    <Col sm={12} className="mb-4 mt-4">
                        <Row>
                            <Col sm={12}>
                                <UncontrolledAlert color="warning" className="alert-label-icon label-arrow ">
                                    <i className="ri-alert-line label-icon"></i><strong>Advertencia!</strong> Este prespuesto es una estimación basada en la actividad histórica de la tienda. El valor final pueder ser diferente y no se conocerá hasta que finalice la ejecución de la campaña. Al activar esta campaña, estás de acuerdo con estas condiciones y aceptas el valor final, cualquiera sea.
                                </UncontrolledAlert>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}><b>Estimación de mensajes a enviar</b></Col>
                            <Col sm={6} className="text-end">{formatIntNumber(budget?.messages || 0)}</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm={6}><b>Estimación de créditos a descontar en Burbuxa</b></Col>
                            <Col sm={6} className="text-end">{formatIntNumber(Math.round(budget?.credits || 0))}</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm={6}><b>Estimación de costo incluyendo fee Meta</b></Col>
                            <Col sm={6} className="text-end">U$S {formatIntNumber(Math.round(budget?.budget || 0))}</Col>
                        </Row>
                    </Col>
                    <Col sm={12}>
                        <div className="hstack gap-2 justify-content-center">
                            {(onSave || onActivate) && <Button color="light" onClick={() => toggle()}>Cancelar</Button>}
                            {(!onSave && !onActivate) && <Button color="light" onClick={() => toggle()}>Cerrar</Button>}
                            {onSave && <Button color="primary" onClick={() => onSave()}><span className="mdi mdi-check"></span>{' '}Guardar</Button>}
                            {onActivate && <Button color="success" onClick={() => onActivate()}><span className="mdi mdi-send-clock"></span>{' '}Guardar y activar</Button>}
                        </div>
                    </Col>

                </Row>
            </form>
        </ModalBody>
    </Modal>
}

export default CampaignModal
