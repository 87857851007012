import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectCampaigns, selectProfile } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';


export enum UserAction {
    EDIT = "EDIT",
    DELETE = "DELETE",
}

const UserActionDropDown = ({ placeholder, userId, onClick }: any) => {
    return (
        <ButtonGroup>
            <UncontrolledDropdown>
                <DropdownToggle tag="a" size='sm' className="dropdown-btn btn" role="button">
                    <i className='mdi mdi-cog'></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => onClick(UserAction.EDIT, userId)}>Editar</DropdownItem>
                    <DropdownItem className='text-danger' onClick={() => onClick(UserAction.DELETE, userId)}>Eliminar</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
    );
};

export default UserActionDropDown;
