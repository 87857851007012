import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
  Container,
  Button,
  UncontrolledTooltip,
  Input,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from 'reactstrap'
import { Link, useSearchParams } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import classnames from 'classnames'
import EmojiPicker from 'emoji-picker-react'

// Import Icons
import FeatherIcon from 'feather-icons-react'
import PersonalInfo from './PersonalInfo'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import {
  getConversations,
  getConversationMessages,
  sendMessage,
  findLastConversationByPhone,
  updateConversation,
} from '../../slices/chat/thunk'

// Selectors
import { selectChat, selectProfile } from 'selectors'
import Spinners from 'Components/Common/Spinner'
import AudioMessagePlayer from './AudioMessagePlayer'
import VideoMessagePlayer from './VideoMessagePlayer'
import ImageMessageDisplay from './ImageMessageDisplay'
import FileMessage from './FileMessageDisplay'
import { createTicket } from 'slices/thunks'
import useCurrentStore from 'Components/Hooks/CurrentStore'

const avatar2 =
  'https://burbuxa.sfo3.cdn.digitaloceanspaces.com/isologo.webp'

const Chat = () => {
  useCurrentStore()
  const userChatShow: any = useRef()

  // Init websocket
  const socket = useRef<WebSocket | null>(null)

  const [customActiveTab, setcustomActiveTab] = useState('1')
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const dispatch = useDispatch<any>()
  const { currentStoreId } = useSelector(selectProfile)
  const [isInfoDetails, setIsInfoDetails] = useState<boolean>(false)
  const [Chat_Box_Username, setChat_Box_Username] = useState<any>('')
  const [Chat_Box_Phone, setChat_Box_Phone] = useState<any>('')
  const [user_Status, setUser_Status] = useState<string | null>('online')
  const [Chat_Box_Image, setChat_Box_Image] = useState<any>('')
  const [Chat_Box_Avatar_Color, setChat_Box_Avatar_Color] = useState<any>('')
  const [currentConversationId, setCurrentConversationId] = useState<any>(null)
  const [curMessage, setcurMessage] = useState<string>('')
  const [reply, setreply] = useState<any>('')
  const [emojiPicker, setemojiPicker] = useState<boolean>(false)
  const emojiPickerRef = useRef<HTMLDivElement | null>(null)
  const [queryParams] = useSearchParams();

  const [searchQuery, setSearchQuery] = useState<string>('')
  const { conversations, conversation, messages, loading } = useSelector(selectChat)


  const converIdRef = useRef(null)

  useEffect(() => {
    const phone = queryParams.get('phone');
    if (phone && currentStoreId) {
      dispatch(
        findLastConversationByPhone({
          storeId: currentStoreId,
          phone,
        }))
    }
  }, [dispatch, currentStoreId, queryParams])


  // Use For Chat Box
  const userChatOpen = (conversation: any) => {
    setChat_Box_Username(conversation.customer_whatsapp_name || conversation.phone_number)
    setChat_Box_Phone(conversation.phone_number)
    setCurrentConversationId(conversation.id)
    setChat_Box_Image(avatar2)
    setUser_Status(conversation.status)
    setChat_Box_Avatar_Color(conversation.avatar_color || '#0936e6')

    // Set assistantEnabled state
    setAssistantEnabled(conversation.assistant_enabled || false)

    if (window.innerWidth < 992) {
      const el = document.querySelector('div.user-chat')

      if (el) {
        el.classList.add('user-chat-show')
      }
    }
  }

  // If we have a conversation (ie phone passed by url), open the chat
  useEffect(() => {
    if (conversation) {
      userChatOpen(conversation);
    }
  }, [conversation])
  // State variables...
  const [assistantEnabled, setAssistantEnabled] = useState<boolean>(false)

  // Function to get initials from a name
  const getInitials = (name: string): string => {
    if (!name) return ''
    const words = name.trim().split(' ')
    const initials = words.map(word => word.charAt(0).toUpperCase()).slice(0, 2)
    return initials.join('')
  }

  // Selector for chat state

  // Utility functions to format dates and times
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit', minute: '2-digit'
    };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  // Use Effect to fetch conversations
  useEffect(() => {
    if (currentStoreId) {
      dispatch(
        getConversations({
          storeId: currentStoreId,
          status: 'OPEN',
          page: 1,
          perPage: 100,
        })
      )
      try {
        socket.current = new WebSocket(
          `wss://apiv2.whatacart.ai/ws/${currentStoreId}`
        )

        socket.current.onopen = () => {
          console.log('SOCKET CONNECTED')
        }

        socket.current.onmessage = (event) => {
          let newMessage = JSON.parse(event.data)

          if (newMessage.action == 'force_refresh') {
            dispatch(
              getConversations({
                storeId: currentStoreId,
                status: 'OPEN',
                all: true,
              })
            )

            if (converIdRef.current !== null) {
              dispatch(
                getConversationMessages({
                  storeId: currentStoreId,
                  conversationId: converIdRef.current,
                  page: 1,
                  perPage: 100,
                })
              )
            }
          }
        }

        socket.current.onclose = () => {
          console.log('SOCKET CLOSED')
        }

        return () => {
          socket.current?.close()
        }
      } catch (error: any) {
        console.log('ERROR ON CONNECT: ', error)
      }
    }
  }, [dispatch, currentStoreId])

  // Use Effect to fetch messages for the selected conversation
  useEffect(() => {
    if (currentConversationId && currentStoreId) {
      converIdRef.current = currentConversationId
      dispatch(
        getConversationMessages({
          storeId: currentStoreId,
          conversationId: currentConversationId,
          page: 1,
          perPage: 100,
        })
      )
    }
  }, [dispatch, currentConversationId, currentStoreId])

  // Toggle Info details offcanvas
  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails)
  }


  const backToUserChat = () => {
    const el = document.querySelector('div.user-chat');
    if (el) {
      el.classList.remove('user-chat-show')
    }
  }

  useEffect(() => {
    console.log('GET MESSAGES: ', messages)

    if (userChatShow.current) {
      const scrollElement = userChatShow.current.contentWrapperEl
      scrollElement.scrollTop = scrollElement.scrollHeight
    }
  }, [messages])

  // Add message
  const addMessage = () => {
    if (curMessage !== '' && currentConversationId) {
      const message = {
        content: curMessage,
        message_type: 'text',
      }
      dispatch(
        sendMessage({
          storeId: currentStoreId,
          conversationId: currentConversationId,
          payload: message,
        })
      )
      setcurMessage('')
      setreply('')
    }
  }

  // Handle Enter key to send message
  const onKeyPress = (e: any) => {
    const { key } = e
    if (key === 'Enter') {
      e.preventDefault()
      addMessage()
    }
  }

  // Copy Message
  const handleClick = (ele: HTMLElement) => {
    const copy = ele
      .closest('.chat-list')
      ?.querySelector('.ctext-content')?.innerHTML
    if (copy) {
      navigator.clipboard.writeText(copy)
    }

    const copyClipboardElement = document.getElementById('copyClipBoard')
    if (copyClipboardElement) {
      copyClipboardElement.style.display = 'block'
      setTimeout(() => {
        copyClipboardElement.style.display = 'none'
      }, 1000)
    }
  }

  // Emoji
  const onEmojiClick = (emojiData: any) => {
    setcurMessage(curMessage + emojiData.emoji)
    // Close the emoji picker after selecting an emoji
    setemojiPicker(false)
  }

  // Effect to handle clicks outside the emoji picker
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setemojiPicker(false)
      }
    }

    if (emojiPicker) {
      // Add event listener when emoji picker is open
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      // Cleanup event listener on component unmount or when emoji picker closes
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [emojiPicker])

  // Function to toggle assistant status
  const handleToggleAssistant = () => {
    const newAssistantStatus = !assistantEnabled
    setAssistantEnabled(newAssistantStatus)

    // Dispatch action to update conversation
    if (currentStoreId && currentConversationId) {
      dispatch(
        updateConversation({
          storeId: currentStoreId,
          conversationId: currentConversationId,
          payload: { assistant_enabled: newAssistantStatus },
        })
      )
    }
  }

  document.title = 'Chat | Burbuxa'

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div className="chat-leftsidebar">
              <div className="px-4 pt-4 mb-3">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h5 className="mb-4">Chats</h5>
                  </div>
                  {/* <div className="flex-shrink-0">
                    <UncontrolledTooltip placement="bottom" target="addcontact">
                      Add Contact
                    </UncontrolledTooltip>

                    <Button
                      color=""
                      id="addcontact"
                      className="btn btn-soft-success btn-sm shadow-none"
                    >
                      <i className="ri-add-line align-bottom"></i>
                    </Button>
                  </div> */}
                </div>
                <div className="search-box">
                  <input
                    type="text"
                    className="form-control bg-light border-light"
                    placeholder="Buscar..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <i className="ri-search-2-line search-icon"></i>
                </div>
              </div>

              <Nav
                tabs
                className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                      active: customActiveTab === '1',
                    })}
                    onClick={() => {
                      toggleCustom('1')
                    }}
                  >
                    Chats
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={customActiveTab} className="text-muted">
                <TabPane tabId="1" id="chats">
                  {loading ? (
                    <Spinners setLoading={() => { }} />
                  ) : (
                    <SimpleBar
                      className="chat-room-list pt-3"
                      style={{ margin: '-16px 0px 0px' }}
                    >
                      <div className="d-flex align-items-center px-4 mb-2">
                        <div className="flex-grow-1">
                          <h4 className="mb-0 fs-11 text-muted text-uppercase">
                            Conversaciones
                          </h4>
                        </div>
                      </div>

                      <div className="chat-message-list">
                        <ul
                          className="list-unstyled chat-list chat-user-list users-list"
                          id="userList"
                        >
                          {(conversations || [])
                            .filter((conversation: any) => {
                              const query = searchQuery.toLowerCase();
                              const name = (conversation.customer_whatsapp_name || '').toLowerCase();
                              const phone = (conversation.phone_number || '').toLowerCase();
                              return name.includes(query) || phone.includes(query);
                            })
                            .map((conversation: any) => (
                              <li
                                key={conversation.id}
                                className={
                                  currentConversationId === conversation.id
                                    ? 'active'
                                    : ''
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => userChatOpen(conversation)}
                                  className={
                                    conversation.unread_count
                                      ? 'unread-msg-user'
                                      : ''
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className={`flex-shrink-0 chat-user-img ${conversation.status === 'OPEN'
                                        ? 'online'
                                        : 'away'
                                        } align-self-center me-2 ms-0`}
                                    >
                                      <div className="avatar-xxs">
                                        <div
                                          className="avatar-title rounded-circle userprofile"
                                          style={{
                                            backgroundColor: conversation.avatar_color || '#0936e6',
                                          }}
                                        >
                                          {getInitials(conversation.customer_whatsapp_name)}
                                        </div>
                                      </div>
                                      <span className="user-status"></span>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden">
                                      <p className="text-truncate mb-0">
                                        {conversation.customer_whatsapp_name || conversation.phone_number}
                                      </p>
                                    </div>
                                    {conversation.unread_count && (
                                      <div
                                        className="flex-shrink-0"
                                        id={'unread-msg-user' + conversation.id}
                                      >
                                        <span className="badge bg-dark-subtle text-body rounded p-1">
                                          {conversation.unread_count}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </SimpleBar>
                  )}
                </TabPane>
              </TabContent>
            </div>

            <div className="user-chat w-100 overflow-hidden" ref={userChatShow}>
              <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                  <div className="position-relative">
                    <div className="p-3 user-chat-topbar">
                      <Row className="align-items-center">
                        <Col xs={12}>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 d-block d-lg-none me-3">
                              <Link
                                to="#"
                                onClick={backToUserChat}
                                className="user-chat-remove fs-18 p-1"
                              >
                                <i className="ri-arrow-left-s-line align-bottom"></i>
                              </Link>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                  {/* Display initials in the topbar */}
                                  {Chat_Box_Username ? (
                                    <div className="avatar-xs">
                                      <div
                                        className="avatar-title rounded-circle userprofile"
                                        style={{
                                          backgroundColor: Chat_Box_Avatar_Color || '#0936e6',
                                        }}
                                      >
                                        {getInitials(Chat_Box_Username)}
                                      </div>
                                    </div>
                                  ) : (
                                    // Default to image if name not available
                                    <img
                                      src={Chat_Box_Image || avatar2}
                                      className="rounded-circle avatar-xs"
                                      alt=""
                                    />
                                  )}
                                  <span className="user-status"></span>
                                </div>
                                <div className="flex-grow-1 d-flex overflow-hidden">
                                  <div className='d-flex flex-column justify-content-center'>
                                    <h5 className="text-truncate mb-0 fs-16">
                                      <a
                                        className="text-reset username"
                                        data-bs-toggle="offcanvas"
                                        href="#userProfileCanvasExample"
                                        aria-controls="userProfileCanvasExample"
                                      >
                                        {Chat_Box_Phone}
                                      </a>
                                    </h5>
                                    <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                      <small>
                                        {user_Status === null
                                          ? '24 Members'
                                          : user_Status}
                                      </small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* Add Switch Toggle */}
                        <Col sm={8} xs={4}>
                          <div className="text-end">
                            <div className="form-check form-switch form-switch-lg d-flex align-items-center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="assistantToggle"
                                checked={assistantEnabled}
                                onChange={handleToggleAssistant}
                              />
                              <label
                                className="form-check-label fs-10"
                                htmlFor="assistantToggle"
                                style={{
                                  lineHeight: '1.5em',
                                }}
                              >
                                Burbujita IA en esta conversación
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="position-relative" id="users-chat">
                      <div
                        className="chat-conversation p-3 p-lg-4 simplebar-scrollable-y"
                        id="chat-conversation"
                      >
                        {loading ? (
                          <Spinners setLoading={() => { }} />
                        ) : (
                          <SimpleBar ref={userChatShow} style={{ height: '100%' }}>
                            <ul
                              className="list-unstyled chat-conversation-list"
                              id="users-conversation"
                            >
                              {(messages || []).map((message: any, index: number) => {
                                const previousMessage = messages[index - 1];
                                const currentDate = formatDate(message.timestamp);
                                const previousDate = previousMessage ? formatDate(previousMessage.timestamp) : null;
                                const isNewDay = currentDate !== previousDate;

                                return (
                                  <React.Fragment key={message.id}>
                                    {isNewDay && (
                                      <div className="message-date-separator text-center my-3">
                                        <span className="badge bg-secondary">
                                          {currentDate}
                                        </span>
                                      </div>
                                    )}
                                    <li
                                      className={
                                        message.direction === 'outgoing'
                                          ? 'chat-list right'
                                          : 'chat-list left'
                                      }
                                    >
                                      <div className="conversation-list">
                                        {message.direction === 'incoming' && (
                                          <div className="chat-avatar">
                                            {/* Display initials or default image */}
                                            {Chat_Box_Username ? (
                                              <div className="avatar-xs">
                                                <div className="avatar-title rounded-circle bg-light userprofile">
                                                  {getInitials(Chat_Box_Username)}
                                                </div>
                                              </div>
                                            ) : (
                                              <img src={Chat_Box_Image || avatar2} alt="" />
                                            )}
                                          </div>
                                        )}
                                        <div className="user-chat-content">
                                          <div className="ctext-wrap">
                                            <div className="ctext-wrap-content">
                                              {message.reply ? (
                                                <div>
                                                  <div className="replymessage-block mb-0 d-flex align-items-start">
                                                    <div className="flex-grow-1">
                                                      <h5 className="conversation-name">
                                                        {message.reply.sender}
                                                      </h5>
                                                      <p className="mb-0">
                                                        {message.reply.msg}
                                                      </p>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                      <button
                                                        type="button"
                                                        className="btn btn-sm btn-link mt-n2 me-n3 font-size-18"
                                                      ></button>
                                                    </div>
                                                  </div>
                                                  <p className="mb-0 ctext-content mt-1">
                                                    {message.content}
                                                  </p>
                                                </div>
                                              ) : (
                                                <p className="mb-0 ctext-content">
                                                  {message.message_type === 'text' &&
                                                    message.content}
                                                  {message.message_type === 'video' && (
                                                    <VideoMessagePlayer
                                                      url={message.media_url}
                                                    />
                                                  )}
                                                  {message.message_type === 'image' && (
                                                    <ImageMessageDisplay
                                                      url={message.media_url}
                                                    />
                                                  )}
                                                  {message.message_type === 'file' && (
                                                    <FileMessage
                                                      url={message.media_url}
                                                    />
                                                  )}
                                                  {message.message_type === 'audio' && (
                                                    <AudioMessagePlayer
                                                      url={message.media_url}
                                                    />
                                                  )}
                                                </p>
                                              )}
                                            </div>
                                            <UncontrolledDropdown className="align-self-start message-box-drop">
                                              <DropdownToggle
                                                href="#"
                                                className="btn nav-btn"
                                                tag="a"
                                              >
                                                <i className="ri-more-2-fill"></i>
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem
                                                  href="#"
                                                  className="reply-message"
                                                  onClick={() =>
                                                    setreply({
                                                      sender: message.sender,
                                                      msg: message.content,
                                                      id: message.id,
                                                    })
                                                  }
                                                >
                                                  <i className="ri-reply-line me-2 text-muted align-bottom"></i>
                                                  Reply
                                                </DropdownItem>
                                                <DropdownItem href="#">
                                                  <i className="ri-share-line me-2 text-muted align-bottom"></i>
                                                  Forward
                                                </DropdownItem>
                                                <DropdownItem
                                                  href="#"
                                                  onClick={(e: any) =>
                                                    handleClick(e.target)
                                                  }
                                                >
                                                  <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                                                  Copy
                                                </DropdownItem>
                                                <DropdownItem href="#">
                                                  <i className="ri-bookmark-line me-2 text-muted align-bottom"></i>
                                                  Bookmark
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </div>
                                          <div className="conversation-name">
                                            <small className="text-muted time">
                                              {formatTime(message.timestamp)}
                                            </small>
                                            {message.direction === 'outgoing' && (
                                              <span className="text-success check-message-icon">
                                                <i className="ri-check-double-line align-bottom"></i>
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                          </SimpleBar>
                        )}
                      </div>
                      {/* copy msg */}
                      <Alert
                        color="warning"
                        className="copyclipboard-alert px-4 fade"
                        id="copyClipBoard"
                        role="alert"
                      >
                        Message copied
                      </Alert>
                      {/* emoji picker */}
                      {emojiPicker && (
                        <div ref={emojiPickerRef}>
                          <EmojiPicker onEmojiClick={onEmojiClick} width={500} height={350} />
                        </div>
                      )}
                    </div>

                    <div className="chat-input-section p-3 p-lg-4">
                      <form id="chatinput-form">
                        <Row className="g-0 align-items-center">
                          <div className="col-auto">
                            <div className="chat-input-links me-2">
                              <div className="links-list-item">
                                <button
                                  type="button"
                                  className="btn btn-link text-decoration-none emoji-btn"
                                  id="emoji-btn"
                                  onClick={() => setemojiPicker(!emojiPicker)}
                                >
                                  <i className="bx bx-smile align-middle"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="chat-input-feedback">
                              Escribe un mensaje
                            </div>
                            <input
                              type="text"
                              value={curMessage}
                              onKeyDown={onKeyPress}
                              onChange={(e) => setcurMessage(e.target.value)}
                              className="form-control chat-input bg-light border-light"
                              id="chat-input"
                              placeholder="Escribe un mensaje... IMPORTANTE: Solo podrás responder si el cliente te escribió hace menos de 24 horas."
                            />
                          </div>
                          <div className="col-auto">
                            <div className="chat-input-links ms-2">
                              <div className="links-list-item">
                                <button
                                  type="button"
                                  disabled={curMessage === ''}
                                  onClick={() => {
                                    addMessage()
                                    setemojiPicker(false)
                                  }}
                                  className="btn btn-success chat-send waves-effect waves-light disable"
                                >
                                  <i className="ri-send-plane-2-fill align-bottom"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </form>
                    </div>

                    <div className={reply ? 'replyCard show' : 'replyCard'}>
                      <Card className="mb-0">
                        <CardBody className="py-3">
                          <div className="replymessage-block mb-0 d-flex align-items-start">
                            <div className="flex-grow-1">
                              <h5 className="conversation-name">
                                {reply && reply.sender}
                              </h5>
                              <p className="mb-0">{reply && reply.msg}</p>
                            </div>
                            <div className="flex-shrink-0">
                              <button
                                type="button"
                                id="close_toggle"
                                className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                                onClick={() => setreply('')}
                              >
                                <i className="bx bx-x align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <PersonalInfo
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(false)}
        currentuser={Chat_Box_Username}
        cuurentiseImg={Chat_Box_Image}
      />
    </React.Fragment>
  )
}

export default Chat
