
//import Components


//Import Flatepicker
import { format } from 'date-fns';
import { Button } from 'reactstrap';
import './ReviewItem.css';
import { useSelector } from 'react-redux';
import { selectProfile } from 'selectors';
import { Permission, hasPermissions } from 'common/security';

const ReviewItem = ({
    update,
    item,
}: any) => {
    const profile = useSelector(selectProfile)

    return <div className='review-item'>
        <div className='review-item-media'>
            {item.type === 'IMAGE' && <img src={item.media} />}
            {item.type === 'VIDEO' &&
                <video controls>
                    <source src={item.media} type={item.content_type} />
                </video>
            }
        </div>
        <div className='review-item-content' style={{ overflow: 'hidden' }}>
            <span className="text-dark font-weight-bold mt-1 text-left">{item.created_at
                ? format(new Date(item.created_at), 'dd/MM/yyyy')
                : '-'}
            </span>
            <h4>{item.product_name}</h4>
            <h5>{item.order_number} | {item.name}</h5>
            <span className="text-dark">{'⭐️'.repeat(item.rate)} ({item.rate} / 5)</span>
            <div className='comment'>{item.comment}</div>
            <td className="text-right">
                {hasPermissions([Permission.REVIEW_APPROVE], profile) && <>
                    {!item.is_public && <Button color='success' size='sm' onClick={() => update(item.id, true)}>
                        <i className="mdi mdi-publish"></i> Publicar
                    </Button>}
                    {item.is_public && <Button color='danger' size='sm' onClick={() => update(item.id, false)}>
                        <i className="mdi mdi-close"></i> Ocultar
                    </Button>}
                </>}
            </td>
        </div>
    </div>
}

export default ReviewItem
